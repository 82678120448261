import axios from "axios";
import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

const Cards = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const cardsPerRow = 2; // Based on your grid setup
  const numberOfRows = 2;
    axios
      .get(
        `https://www.cron-media.com:8080/adminTemplates.php?id=&limit=${
          cardsPerRow * numberOfRows
        }`)
      .then(output => {
        console.log(output.data);
        setData(output.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  // Number of cards per row and number of rows to display
  
  // const visibleCards = data.slice(0, cardsPerRow * numberOfRows);

  return (
    <>
      <section className="leading-relaxed mt-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {data.map((value, index) => {
            return (
              <div
                key={index}
                className="max-w-full rounded-2xl shadow-lg bg-white cursor-pointer transition-all duration-200 ease-in hover:shadow-xl hover:scale-[1.01] border border-gray-300">
                <div className=" border-b border-gray-300">
                  {value.Small_preview.length !== 0 ? (
                    <img
                      src={`https://www.cron-media.com:8080/Templates/Images/${value.Small_preview[0]}`}
                      alt={value.Template_Name}
                      loading="lazy"
                      className="w-full h-[60%] rounded-t-2xl object-cover"
                    />
                  ) : (
                    <img
                      src="https://via.placeholder.com/300X200"
                      alt={value.Template_Name}
                      loading="lazy"
                      className="w-full h-[60%] rounded-t-2xl object-cover"
                    />
                  )}
                </div>
                <div className="px-4 pb-4">
                  <div className="flex space-x-2 my-2 line-clamp-1">
                    {value.Tags?.map((tag, index) => (
                      <span
                        key={index}
                        className="text-xs text-nowrap border border-gray-300 text-black px-2 py-1 rounded-full h-fit">
                        {tag}
                      </span>
                    ))}
                  </div>
                  <h4 className="text-lg font-medium mb-2 line-clamp-1">
                    {value.Template_Name}
                  </h4>
                  <p className="text-gray-700 text-sm mb-4 line-clamp-2">
                    {value.Template_Desc}
                  </p>

                  <div className="flex justify-between items-center">
                    <span className="text-sm font-bold text-black">
                      {value.Assets} Assets
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Cards;
