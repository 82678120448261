const TermsConditions = ({ showFooter, setShowFooter }) => {
  setShowFooter(true);

  return (
    <div className="bg-slate-200 py-6 px-10">
      <main class="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-6xl">
        <section class="">
          <h2 class="text-xl  font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            1. Introduction
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            Welcome to cronMedia. These Terms & Conditions govern your use of
            our website, services, and templates provided by cronMedia. By
            accessing or using our site, you agree to comply with these terms.
            If you do not agree, please do not use our site.
          </p>

          <h2 class="text-xl  font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            2. Use of Templates
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            Our templates are provided for your personal and commercial use
            under the license specified with each template. You may not
            redistribute, resell, or use the templates in any manner not
            explicitly allowed by our license.
          </p>

          <h2 class="text-xl  font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            3. User Responsibilities
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            You are responsible for maintaining the confidentiality of your
            account information and for all activities that occur under your
            account. You agree to notify us immediately of any unauthorized use
            of your account or any other breach of security.
          </p>

          <h2 class="text-xl  font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            4. Intellectual Property
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            All content and materials on the cronMedia site, including but not
            limited to text, graphics, logos, and templates, are the property of
            cronMedia and are protected by copyright and other intellectual
            property laws.
          </p>

          <h2 class="text-xl  font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            5. Limitation of Liability
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            cronMedia is not liable for any damages or losses resulting from
            your use of our site or services. This includes, but is not limited
            to, direct, indirect, incidental, or consequential damages.
          </p>

          <h2 class="text-xl  font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            6. Changes to Terms
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            We may update these Terms & Conditions from time to time. Any
            changes will be posted on this page with an updated effective date.
            Your continued use of the site after any changes constitutes your
            acceptance of the new terms.
          </p>

          <h2 class="text-xl font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            7. Governing Law
          </h2>
          <p class="mb-6 text-lg leading-relaxed">
            These Terms & Conditions are governed by and construed in accordance
            with the laws of [Your Country/State], without regard to its
            conflict of law principles.
          </p>

          <h2 class="text-xl font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            8. Contact Us
          </h2>
          <p class="text-lg leading-relaxed">
            If you have any questions or concerns about these Terms &
            Conditions, please contact us at 
            <a
              href="mailto:support@cron-media.com"
              class="text-blue-600 hover:underline">
              {' '} support@cron-media.com
            </a>
            .
          </p>
        </section>
      </main>
    </div>
  );
};

export default TermsConditions;
